.secondSection{
    padding: 100px 70px;
    position: relative;
    min-height: 80vh;
    align-items: center;
    background: #fff;
    display: flex;
    justify-content: space-around;
    .center{
            display: flex;
            justify-content: center;
          }
  .partA{
      position: absolute;
      left: 200px;
    .imgContainer{
      display: flex;
      .img1{
        z-index: 100;
      }
      .flex{
        display: flex;
        flex-direction: column;

        .img2{
          margin: -30px
        }
        .img3{
          margin-top: 40px;
        }
      }
    }
    .head{
        font-family: 'Aristotelica Display Trial';
        font-style: normal;
        font-weight: 600;
        font-size: 90px;
        line-height: 80%;
        width: 563px;
        margin-bottom: 20px;
        color: #FFFFFF;
    }
    .subhead{
        width: 494px;
        height: 80px;
        left: 192px;
        top: 482px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
    }
    .btnCon{
        display: flex;
        .btn{
            display: flex;
            align-items: center;
            margin:0 10px 0 0;
            background: #FFABBC;
            border-radius: 5px;
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: #fff;
            border: none;
            outline: none;
            max-width: 220px;
            // padding: 10px 32px 10px 33px;
            margin-right: 30px;
            width: 220px
        }
      
    }
    @media (max-width:1610px) {
    left: 100px;
      @media (max-width:1275px) {
        left: 50px;
        @media (max-width:1210px) {
          transform: scale(0.8);
          margin-top: -70px;
          left: 0;
          @media (max-width:997px) {
            position: sticky;
            transform: scale(1.4);
            margin-top: 80px;
            @media (max-width:845px) {
              transform: scale(1);
              margin-left: -20px;
              margin-top: -30px;
              @media(max-width :575px){
                  transform: scale(0.8);
                  margin-left: 0px;
                  @media (max-width:480px) {
                    transform: scale(0.7);
                    margin-top: -100px;
                    margin-left: -40px;
                    @media (max-width:422px) {
                      transform: scale(0.65);
                      @media (max-width:376px) {
                        margin-left: -20px;
                        @media (max-width:346px) {
                          transform: scale(0.58);
                        }
                      }
                    }
                  }
              }
            }
          }
        }
      }
    }

  }
  .partB{
    position: absolute;
    top: 150px;
    right: 150px;
    .head{
      width: 643px;
      height: 142px;
      left: 717px;
      top: 1073px;
      font-family: 'Aristotelica Display Trial';
      font-style: normal;
      font-weight: 600;
      font-size: 75px;
      line-height: 95%;
      color: #190714;
      @media (max-width:1110px) {
        font-size: 60px;
        height: unset;
        width: 543px;
        @media (max-width:575px) {
          .center2{
            display: flex;
            justify-content: center;
          }
          font-size: 36px;
          width: 275px;
          margin: -80px auto 10px auto;
          
        }
      }
    }
    .subhead{
      width: 460px;
      height: 80px;
      margin: 20px 0;
      left: 717px;
      top: 1225px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #190714;

      @media (max-width:575px) {
        font-size: 12px;
        width: 255px;
        line-height: 15px;
        margin: 0 auto;
        // margin: -50px auto 10px auto;
      }

    }
    .btnCon{
      display: flex;
      // width: 220px;
      // justify-content: space-between;
      max-width: "460px";
      .btn{
          display: flex;
          align-items: center;
          margin:0 10px 0 0;
          background: #FFABBC;
          border-radius: 5px;
          font-family: 'Aristotelica Display Trial';
          font-weight: 600;
          font-size: 24px;
          line-height: 26px;
          margin-right: 40px;
          color: #fff;
          border: none;
          outline: none;
          max-width: 220px;
          padding: 10px 32px 10px 33px;
          width: 220px
      }
      @media (max-width:550px) {
        margin-left: 50px;
        @media(max-width:442px){
          margin-left: 32px;
        }
      }
      @media (max-width:520px) {
        transform: scale(0.9);
      }
      @media (max-width:425px) {
       transform: scale(0.7); 
       @media (max-width:400px) {
        // margin-right: -25px;
        @media (max-width:352px) {
          transform: scale(0.6);
        }
      }
      }
      }
  @media (max-width:1610px) {
    right: 50px;
    @media (max-width:1425px) {
      right: -50px;
      }
      @media (max-width:997px) {
        position: sticky;
        margin-top: 150px;
        @media (max-width:845px) {
            margin: 50px auto 0 auto;
            @media (max-width:575px) {
              margin: 50px 0;
              
            }
        }
      }
  }
  
  }
@media (max-width:1210px) {
  flex-direction: column;
}
    
}

