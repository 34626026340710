.firstSection{
    padding: 100px 70px;
    position: relative;
    height: 80vh;
    background: #FFABBC;
    display: flex;
    justify-content: space-around;
  .partA{
      position: absolute;
      left: 200px;
    .head{
        font-family: 'Aristotelica Display Trial';
        font-style: normal;
        font-weight: 600;
        font-size: 90px;
        line-height: 80%;
        width: 563px;
        margin-bottom: 20px;
        color: #FFFFFF;
       
        @media (max-width:720px) {
          width: 435px;
          height:unset;
          font-size: 60px;
          line-height: 80%;
          display: flex;
          justify-content: center;
          margin: 20px auto;

          @media (max-width:460px) {
            width: 400px;
            font-size: 54px;
            @media (max-width:402px) {
              width: 239px  ;
              font-size: 36px;
              padding: 10px;
          }
          }
      }
    }
    .subhead{
        width: 494px;
        height: 80px;
        left: 192px;
        top: 482px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        @media (max-width:720px) {
          margin-bottom: 40px !important;

          @media (max-width:580px) {
            width: 354px;
            height: 54px;
            font-size: 18px;
            line-height: 15px;
            margin: 20px auto;
  
            @media (max-width:460px) {
              width: 300px  ;
              font-size: 12px;
              @media (max-width:402px) {
             width: 254px;
            font-size: 12px;
            }
          }
        }
      }
        
    }
    .btnCon{
        display: flex;
        @media (max-width:580px) {
          display: flex;
        // justify-content: space-evenly;
        max-width: 100%;
        box-sizing: border-box;
        margin: auto;
        margin-left: -40px;
        @media (max-width:520px) {
          transform: scale(0.9);
          @media (max-width:448px) {
            margin-left: -22px;
            transform: scale(1.2);
            @media(max-width:428px){
              margin-left: -35px;
            }
          }
        }
        @media (max-width:425px) {
         transform: scale(1); 
         @media (max-width:400px) {
          margin-left: -63px;
          @media (max-width:356px) {
            margin-left: -65px;
          }
      
          @media (max-width:352px) {
          margin-left: -23px;
            
          }
        }
        }
        }

        .btn{
            display: flex;
            align-items: center;
            margin:0 10px 0 0;
            background: #FFABBC;
            border-radius: 5px;
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: #fff;
            cursor: pointer;
            border: none;
            outline: none;
            max-width: 220px;
            margin-right: 30px;
            width: 220px;
            @media (max-width:580px) {
              width: 50%;
              position: relative;
              height: 20px;
              @media (max-width:448px) {
                @media(max-width:428px){
                  margin-right: 48px;

                  @media(max-width:400px){
                    margin-right: 74px;
                  }
                }
                width: 35%;
              }
            
              @media (max-width:356px) {
                margin-right: 35px;
                width: 50%;
              }
             @media (max-width:350px) {
              margin-right: -10px;
             }

             background-color: transparent;
              img{
                
                transform: scale(0.8);
                @media (max-width: 561px) {
                  // margin-right: 70px;
                }
                @media (max-width:475px) {
                  transform: scale(0.6);
                  @media (max-width:350px) {
                  }
                }
              }
            }
        }
        @media (max-width:580px) {
          // width: 80%;
          // margin: 10px auto;
        }
    }
    @media (max-width:1386px) {
      left: 100px;

      @media (max-width:1070px) {
        position: unset;
        margin: auto;
      }
    }
  
  }
  .partB{
      position: absolute;
    top: -70px;
    right: 100px;

    @media (max-width:1580px) {
      right: -100px ;

      @media (max-width:1220px) {
        right: -200px;
      }
      @media (max-width:1070px) {
        position: sticky;
       display: flex;
       margin-left: -250px;
       justify-content: center;

       @media (max-width:720px) {
         margin-left: -150px;
       }
      }
    }
    
  }
  @media (max-width:1070px) {
      flex-direction: column;
      height: unset;
      @media (max-width:720px){
        padding-top: 40px;
        @media (max-width:600px) {
          display: block;
          @media (max-width:350px) {
            padding: 20px 0;
          }
        }
      }
  }
}