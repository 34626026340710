.period{
       display: flex;
        width:599px ;
        flex-direction: column;
        margin: 20px 30px;
        @media (max-width:1485px) {
            width: 599px;
            @media (max-width: 1000px){
                @media (max-width: 860px){
                    width: 100%;
                    margin:20px unset;
                }
                // width: 100%;
            }
        }
        
    .flex_1{
        width:100%;
        height: 400px;
        padding: 20px;
        background-color: #FFABBC;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50px 50px 0px 0px;
        @media (max-width:427px) {
            height: 252px;
        }
    }
    .flex_2{
        width:100%;
        height:  185px;
        background: rgba(255, 255, 255,1);
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 50px 50px ;
        padding: 10px 50px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1029px) {
            overflow: hidden;
            box-sizing: border-box;
            // flex-direction: column;
            // .btn {
            //     position: relative;
            //     bottom: 20%;
            // }
            .img_container { 
                width: 300px !important;
                // background: blue;
                padding: 10px 10px !important;
            }
            padding: 10px 10% !important;
            // flex-wrap: nowrap;
            .support_text {}

        }

        .flex{
            @media (max-width: 1029px){
                width: 70% !important;
                @media (max-width: 1005px){
                    width: 100%;
                }
            }
            .support_text{
                font-family: 'Aristotelica Display Trial';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 93.5%;
                color: #FFABBC;
                margin: 10px 0;
              
                @media (max-width:658px) {
                    font-size: 10px;
                }
            }
            .bias_text{
                font-family: 'Aristotelica Display Trial';
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 93.5%;
                color: #190714;
                margin: 5px 0;
                @media (max-width:1108px) {
                    font-size: 32px;
                    line-height: 90%;
                    word-wrap: break-word;

                    @media (max-width:1029px) {
                        font-size: 28px;

                        @media (max-width:658px) {
                            font-size: 20px;
                        }
                    }
                }
                
            }
            .img{
                transform: scale(0.8);
                margin:  10px 5px
            }
            
        }
        .btn{
            outline: none;
            border: none;
            width: 173.4px;
            height: 51px;
            background: #FFABBC;
            padding: 10px;
            margin-top: 40px;
            border-radius: 5px;
            text-align: center;
            justify-content: center;
            display: flex;
            span{
                font-family: 'Aristotelica Display Trial';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                text-align: center;
                width: 118px;
                margin: auto;
                line-height: 13px;
                color: #FFFFFF;

            }
            @media (max-width: 900px) {
                width: 40% !important;
                margin-right: 10px;
            }
            @media (max-width:658px) {
                margin-top: 60px;
                width: 120px;
                padding:10px 25px
            }
             @media (max-width:450px) {
                margin-top: 40px;
                width: 90px;
                padding:10px 25px
            }
        }
        @media (max-width:1485px) {
            height: unset;
            height: 235px;
        }
    }
    @media (max-width:658px) {
        width: 90%;
        margin: 40px auto;
        .logo{display: none}
    }
}