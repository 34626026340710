.bias{
    width: 1240px;
    display: flex;
    margin: -50px auto 100px auto;
    flex-direction: column;
    @media(max-width:995px){
        display: none;
    }
    .section_1{

        background: url(../../assets/images/Bias/Bias.jpeg) no-repeat;
        border-radius: 50px 50px 0px 0px;
        background-color: #E2E1DD;
        padding: 50px 100px;
        min-height: 400px;
        background-size: cover;
        background-position: 420px -10px;
        @media (max-width:1260px){
        background-position: 560px -100px;
        padding: 50px 200px;
        }
        @media(max-width:1335px){
            padding: 50px 100px;
            background-position: 400px -100px;
        }
        
        .flex{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .flex_text{
                font-family: 'Aristotelica Display Trial';
                font-style: normal;
                font-weight: 600;
                font-size: 52px;
                width: 383px;
                margin: 40px 0;
                line-height: 93.5%;
                color: #883A28;
                @media(max-width:995px){
                    color:#7e1c06;
                    width: 100%;
                }
            }
        }

    }
    .section_2{
        height:  280px;
        background: rgba(255, 255, 255,1);
        box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 50px 50px ;
        padding: 50px 100px;
        display: flex;
        justify-content: space-between;
        .flex{
            width: 460px;
            .support_text{
                font-family: 'Aristotelica Display Trial';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 93.5%;
                color: #FFABBC;
                margin: 10px 0;
            }
            .bias_text{
                font-family: 'Aristotelica Display Trial';
                font-style: normal;
                font-weight: 600;
                font-size: 60px;
                line-height: 93.5%;
                color: #190714;
                margin: 10px 0;

            @media(max-width:627px){
                font-size: 40px;
            }
            }
            .img{
                transform: scale(0.8);
                margin:  10px 5px
            }
            @media(max-width:995px){
                width: 100%;
            }
        }
        .btn{
            outline: none;
            border: none;
            width: 289px;
            height: 85px;
            background: #FFABBC;
            padding: 10px;
            margin-top: 40px;
            border-radius: 5px;
            span{
                font-family: 'Aristotelica Display Trial';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                text-align: center;
                width: 118px;
                margin: auto;
                line-height: 26px;
                color: #FFFFFF;

            }
            @media(max-width:1056px){
                width: 200px;
            }
            @media(max-width:995px){
                height: 60px;
                width: 100%;
                margin: 20px auto;
            }
        }
        @media(max-width:1335px){
            padding: 50px 100px;
        }
        @media(max-width:1056px){
            padding: 20px 100px;
        }
        @media(max-width:995px){
            flex-direction: column;
            min-height: 380px;
            padding: 50px;
        }   
    }
    @media (max-width:1260px) {
        width: 90%;
    }
}