.about {
  .diff{
    color: #FFABBC; 
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  .section1{
    background-color:#FFF7F8 ;
    padding: 100px 40px;
    @media (max-width:1058px) {
      height: unset;
      min-height: 40vh;
    }
    .head{
      font-family: 'Aristotelica Display Trial';
      width: 1025px;
      margin: 10px auto 20px auto;
      font-style: normal;
      font-weight: 600;
      font-size: 70px;
      text-align: center;
      color: #190714;
      .diff{
        color:  #FFABBC;
      }
      @media (max-width:1058px) {
        width: 80%;

        @media (max-width:800px) {
          width: 90%;
          @media (max-width:560px) {
            margin-top: -40px;
            width: 272px;
            font-size: 36px;
            line-height: 93.5%;
          }
        }
      }
    }
    .subhead{
      width: 765px;
      margin: auto;
      height: 62px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      // background-color: red;
      color: #ABA5A6;
      @media (max-width:1058px) {
        width: 80%;
        @media (max-width:560px) {
          width: 313px;
          font-size: 12px;
          line-height: 15px;
          
          @media(max-width:336px){
            width: 276px;
          }
        }
      }
        
    }
    .flex {
      max-width: 60%;
      margin: 10px auto;
      display: flex;
      justify-content: space-between;

      h1 {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 600;
        font-size: 65px;
        line-height: 93.5%;
        text-align: center;
        color: #FFABBC;
        @media (max-width:1058px) {
          font-size: 45px;
          @media (max-width:667px) {
            font-size: 30px;
            @media (max-width:560px) {
              font-size: 24px;
              @media(max-width:3286px){
                font-size: 20px;
                }
            }
          }
        }
          
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 33px;
        text-align: center;
        color: #190714;
        @media (max-width:667px) {
          font-size: 20px;
          @media (max-width:560px) {
            font-size: 12px;
            line-height: 13px;
            @media(max-width:3286px){
            font-size: 10px;
            }
          }
        }
          
      }
      @media (max-width:1058px) {
        margin-top: 50px;
        min-width: 80%;
        @media (max-width:800px) {
          width: 90%;
          @media (max-width:560px) {
            margin-top: 10px;
            @media(max-width:336px){
              width: 100%;
            }
          }
        }
      }
        
    }
      }
      
.section2{
  .card{
    width: 1240px;
    height: 617px;
    margin: 80px auto 120px auto;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 15px 70px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 100px;
    display: flex;
    position: relative;
    @media(max-width:1304px){
        width: 90%;
        @media(max-width:1132px){
         padding: 100px 50px;
         @media (max-width:1077px) {
           flex-direction: column;
           height: unset;
          //  min-height: 617px;
           padding: 50px ;
           @media (max-width:553px) {
           padding-bottom: -20px;
           @media (max-width:364px) {
           padding: 8px;
          }
           }
         }
        }
      }
    .head{
      width: 367px;
      height: 96px;
      font-family: 'Aristotelica Display Trial';
      font-style: normal;
      font-weight: 600;
      font-size: 75px;
      line-height: 68px ;
      color: #190714;
      @media (max-width:1077px) {
        margin:0 auto 30px auto;
        font-size: 60px;
        line-height: 50px;
        margin-top: 15%;
        margin-bottom: 15%;

        @media (max-width:553px) {
          width: 193px;;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 10px;
          font-size: 36px;
          line-height: 100%;
        }
      }
    }
    .subhead{
      width: 513px;
      height: 211px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin: 40px auto 0px auto;
      color: #ABA5A6;
      @media (max-width: 1077px){
        width: 90% !important;
        line-height: 32px;
        margin-bottom: 5%;
        @media (max-width: 800px){
          margin-bottom: 10%;
          @media (max-width: 704px){
            margin-bottom: 18%;
            @media (max-width: 630px){
              margin-bottom: 25%;
              @media (max-width: 573px){
                margin-bottom: 34%;
                @media (max-width:553px) {
                width: 288px;
                font-size: 12px;
                margin: -20px auto -80px auto;
                line-height: 15px;
                @media (max-width:462px) {
                  margin: -17px auto -65px auto;
                  @media (max-width:408px) {
                    margin: -17px auto 7px auto;
                  }
                }
                }
              }
            }
          }
        }
      }
    }
    .imgDiv{
      position: absolute;
      right: -100px;
      top:-150px;
      @media(max-width:1250px) {
        right: -200px;
        @media (max-width:1077px) {
          position: sticky;
          margin-top: -150px;
          @media (max-width:713px) {
            transform: scale(0.8);
            display: flex;
            justify-content: center;
            margin-left: 400px;
            margin-top: -200px;
            @media (max-width:460px) {
                    margin-left: 350px;
            }
          }
          img {
            transform:rotate(-18deg) translateX(-20%);
            @media(max-width: 963px) {
              transform:rotate(-18deg) translateX(-25%);
              @media(max-width: 800px) {
                transform:rotate(-18deg) translateX(-30%);
                @media(max-width: 772px) {
                  transform:rotate(-18deg) translateX(-34%);
                  
                }
              }
            }
          }
        }
      }
    }
      .btnCon{
            display: flex;
            max-width: "460px";
            .img{
              display: none;
            }
            .btn{
                display: flex;
                align-items: center;
                margin:0 10px 0 0;
                background: #FFABBC;
                border-radius: 5px;
                font-weight: 600;
                font-size: 24px;
                line-height: 26px;
                color: #fff;
                border: none;
                outline: none;
                max-width: 220px;
                padding: 10px 32px 10px 33px;
                width: 220px;
                @media (max-width:553px) {
                  display: none;
                }
            }
            @media (max-width: 1077px) {
              width: 80%;
              // gap: 10%;
              justify-content: space-between;
              margin: auto;
              // background: red;
              @media (max-width:553px) {
                width: 260px;
                margin:-40px auto;
                .img{
                  display: flex;
                  justify-content: center;
                }
              }
            }
        }
        
  }
  .connect{
    height: 678px;
    background: rgba(255, 171, 188, 0.1);
    padding: 100px;
    position: relative;
    @media (max-width:1220px) {
      padding: 100px 50px;
      @media (max-width:1015px) {
        height: unset;
        min-height: 678px;  
        @media (max-width:462px) {
         padding: 80px 8px;
        }
      }
    }
   .w1240{
     width: 1240px;
     margin: auto;
     display: flex;
     justify-content: space-between;
     @media (max-width:1400px) {
       width: 90%;
       @media (max-width:1015px) {
         flex-direction: column;
       }
     }
    .partA{
      .head{
        width: 447px;
        height: 209px;
        left: 104px;
        top: 2766px;
        font-family: 'Aristotelica Display Trial';
        font-style: normal;;
        font-weight: 600;
        font-size: 75px;
        line-height: 80%;
        color: #190714;
        @media (max-width:1015px) {
          width: 80%;
          margin: auto;
          height: unset;
          text-align: center;
          font-size: 60px;
          margin-bottom: 20px;
          @media (max-width:816px) {
            font-size: 50px;
            @media (max-width:720px) {
              font-size: 36px;
              line-height: 80%;
              width: 312px;
              @media (max-width:462px) {
                font-size: 24px;
                width: 273px;
              }
            }
          }
        }

      }
      .subhead{
        width: 431px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #190714;
        @media (max-width:1015px) {
          width: 85%;
          margin:20px auto;
          height: unset;
          @media (max-width:720px) {
            font-size: 12px;
            line-height: 15px;
            width: 296px;
            text-align: center;
            @media (max-width:316px) {
              width: 259px;
            }
          }
        }

      }
      button{
      width: 329px;
      height: 75px;
      margin: 60px 0;
      outline: none;
      border: none;
      background: #FFABBC;
      border-radius: 5px;
      span{
        font-family: 'Aristotelica Display Trial';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
        color: #FFFFFF;
        @media (max-width:400px) {
          font-size: 18px;
        }

      }
      @media (max-width:1015px) {
        position: absolute;
        bottom: 0;
        width: 80%;
        margin: 20px auto;
        @media (max-width:462px) {
         width: 90%;
         display: flex;
         justify-content: center;
         align-items: center;
        }
      }
    }
      }
    .partB{
      margin-top: -50px;
      @media (max-width:1220px) {
        img{
          transform: scale(0.8);
          @media (max-width:1183px) {
            margin-left: -100px;
            @media (max-width:1015px) {
              transform: scale(1);
              width: 332px;
              display: flex;
              justify-content: center;
              margin: 50px auto;
              @media (max-width:366px) {
                width: 281px;
                @media (max-width:302px) {
                  width: 255px;
                }
              }
            }
          }
        }
      }
    }
   }
      }
  }
}