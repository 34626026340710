.drawer{
    width: 95%;
    margin: -100px auto;
    min-height: 314px;
    background: #FFFFFF;
    box-shadow: 0px 15px 70px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: Roboto;
    font-style: normal;
    position:fixed;
    left: 20px;
    font-weight: 500;
    font-size: 18px;
    z-index: 200;
    transform: translateY(-400%);
    transition: all 0.6s ease-in-out;

  @media (max-width:560px) {
      left: 15px;
    @media (max-width:526px) {
        left: 10px;
        @media (max-width:460px) {
         width: 400x;
         position: fixed;
        //  left: 0;
         @media (max-width:400px) {
             width: 350px;
            left: 20px;
             @media (max-width:380px) {
                 left: 10px;
                @media (max-width:356px) {
                    width: 300px;
                    left: 15px;
                    @media (max-width:318px) {
                        width: 280px;
                        left: 10px;
                    }
                }
             }
         }
     }
  }
       
   }
    &.show{
        transform: translateY(0);
    }
    
    .logo{
        position: absolute;
        left:1%;
        top: 5%;
        transform: scale(0.6);
    }
    .dismiss{
        position: absolute;
        right:5%;
        top: 5%;
        font-size: 60px;
        cursor: pointer;
        &:hover{
            color: red;
        }
        @media (max-width:460px) {
          font-size: 40px;
        }
    }
   .links{
       padding: 100px 30px 20px 30px;

       .btn{
        width: 100%;
        margin: auto;
        outline: none;
        border: none;
        background: #FFABBC;
        color: #fff;
        border-radius: 5px;
        padding: 10px;
        margin-top: 30px;

        a{
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 22px;

            color: #FFFFFF;

        }
    }
   }
    
    .links>li>a{
        display:flex;
        // justify-content: center;
        flex:1;
        padding:0.7rem 1rem;
        text-decoration: none;
        font-family: 'Aristotelica Display Trial';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #190714;
        &:hover{
            background: #FFABBC;
            color:#f4f4f4
        }

        
        
    }
  


    @media(min-width:981px){
        .drawer{
            display:none;
        }
    }
}



