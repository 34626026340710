.leave{
    margin: 160px auto 50px auto;
    height: 45vh;
    @media (max-width:825px) {
        width: 90% !important;
        margin: 50px auto;
        @media (max-width:462px) {
            width: 100%;
            margin-bottom: 142px;
          }
    }
    .head{
        font-family: 'Aristotelica Display Trial';
        font-style: normal;
        font-weight: 600;
        font-size: 75px;
        line-height: 80%;
        text-align: center;
        color: #190714;
        margin: 20px 0;
        width: 861px;
        @media(max-width:913px){
            width: 50%;
            margin: 20px auto;
            @media(max-width:720px){
                width: 90%;
               }
            font-size: 60px;
            @media(max-width:500px){
                width: 261px;
                font-size: 36px;
                line-height: 100%;
               }
           }
        .diff{
            color: #FFABBC;
        }

    }
    .subhead{
        width: 593px;
        height: 60px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #190714;
        margin: 20px auto;
        @media(max-width:913px){
            width: 60%;
            margin: 20px auto;
            @media(max-width:500px){
                width: 266px;
                font-size: 13px;
                line-height: 15px;
               }
           }
    }
    form{
        position: relative;
        width: 720px;
        margin:30px auto 150px auto;
        @media(max-width:825px){
            width: unset;
            display:flex;
            flex-direction: column;
           }
        &>input{
            width: 720px;
            background: #FFFFFF;
            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
            border: none;
            outline: none;
            padding: 20px 40px;
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 22px;
            &::placeholder{
                color: rgba(196, 196, 196, 0.5);
            }
            
            @media(max-width:825px){
                width: 80%;
                margin: 20px auto;
                background: #FFFFFF;
                box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
                border-radius: 5px;

                @media(max-width:500px){
                    width: 324px;
                   }
               }
        }
        button{
            outline: none;
            width: 213px;
            border: none;
            background: #FFABBC;
            padding: 20px;
            position: absolute;
            right: 1px;
            @media(max-width:825px){
                width: 80%;
                margin: 20px auto;
                box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                position: relative;

                @media(max-width:500px){
                    width: 324px;
                   }
               }
            span{
                font-family: 'Aristotelica Display Trial';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 26px;
                color: #FFFFFF;
                
            }
        }
    }
}