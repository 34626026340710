#root{
    max-width: unset !important; 
    min-width: unset !important;
}

.header{
    width: 100%;
    height:auto; 
    color: #fff;
    display: flex;
    justify-content:space-between;
    align-items: center;
    box-shadow: 12px 12px 25px #0000001a;
    padding:10px 100px;
    z-index: 50;
    position: relative;
    .logo h2{
        color:#f4f4f4;
        font-size: 1.4rem;
        cursor: pointer;
    }
    .logo img{
        transform: scale(0.9);
        @media (max-width:460px) {
            transform: scale(0.8) !important;
        }
    }
    
    .links{
        display: flex;
        list-style: none;
        align-items: center
    }
    .links>li{
        padding-left: 1.5rem;
    }
    .links>li>a{
        font-size: 40px;
        font-family: Roboto;
    }
    .download__button{
        border-radius:5px;
        padding: 14px 27px ;
        margin-left: 20px;
        cursor: pointer;
    }
    .download__button a{
        // color: #190714 !important;
        text-align: center;
    }
    .links>li>a{
        text-decoration:none;
        font-family: 'Aristotelica Display Trial';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;
        margin: 0 20px;
        line-height: 28px;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
    }
    .hamburger__menu{
        display: none;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor:  pointer;
        transform: scale(1.4em);
    }
    
    /* Media Queries */
    
    @media(max-width:1096px){
      padding:20px 30px;
    }
    
    
    @media(max-width:980px){
        box-shadow: none;
        .links{
        display:none;
        }
        .hamburger__menu{
            display:flex;
        }
    }
    
    @media (max-width:700px) {
        .logo img{
            transform: scale(0.7);
        }
    }
    @media(max-width:600px){
        .logo img{
        transform: scale(1);
    }
    }
    @media(max-width:500px){
        .logo h2,.hamburger__menu{
            font-size: 0.2rem;
            @media (max-width:400px) {
            margin-top: -20px;
            }
        }
    }
}

