.footer{
    // position: relative;
    bottom: 0;
    width: 100vw;
    position: relative;
    background: #FFF7F8;
    padding:40px 60px 100px 60px;
    display:flex;
    justify-content: space-between;
    .content{
        h2{
        font-family: 'Aristotelica Display Trial';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 33px;
        color: #190714;
        margin: 20px 0;
        @media (max-width:400px) {
            font-size: 18px !important;
        }
        }
        p{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #190714;
            @media (max-width:400px) {
                font-size: 12px !important;
                line-height: 18px;
            }
            &.spec{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                margin-top: 10px;
                color: #190714;
                
            }
        }
        &.w-259{
            width: 259px;
           
        }
        li>a{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            margin: 200px 0 20px 0;
            text-decoration: none;
            color: #190714;
            @media (max-width:400px) {
               font-size: 12px;
            }
        }
    }
    @media(max-width:1020px){
        flex-direction: column;
        .content{
            &.w-259{
                width: 100%;
            }
        }
        
    }
    @media(max-width:1020px){
        padding: 10px 20px;
        flex-direction: column;
        .content{
            &.w-259{
                width: 259px;
                @media(max-width:900px){
                    width: 70%;
                    @media(max-width:624px){
                        width:100%       
                    }
                }
            }
        }
        
    }
    @media (max-width:900px){
        .abs{
            position: absolute;
            right: 40px;
            top: 160px;
            @media (max-width:400px) {
                right: 14px;
                top: 154px;
            }
        }
    }
    @media (max-width:900px) {
        padding-bottom: 100px;
    }
    @media (max-with:400px) {
        font-size: 18px !important;
    }
}