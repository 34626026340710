.homepage {
  height: 100%;
  color: #002;
  .cardB{
    width: 1240px;
    margin:50px auto;
    @media (max-width:1260px) {
      width: 90%;
      margin: 100px auto;
    }
  }
  .cardC{
    width: 100%;
    margin:0 auto 100px auto;
    background: #FFF7F8;
  }

}
