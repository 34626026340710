.blog{
    .w1240{
        margin: 20px auto;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(600px,max-content));
        grid-gap: 50px;
        
        
    }
    .leave2{
        margin: 160px auto 50px auto;
        height: 45vh;
        width: 946px;
        @media (max-width:825px) {
            width: 90% !important;
            margin: 50px auto;
        }
        .head{
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            font-weight: 600;
            font-size: 75px;
            line-height: 80%;
            text-align: center;
            color: #190714;
            margin: 20px 0;
            width: 861px;
            @media(max-width:913px){
                width: 50%;
                margin: 20px auto;
                @media(max-width:720px){
                    width: 90%;
                   }
                font-size: 60px;
                @media(max-width:500px){
                    width: 247px;
                    font-size: 36px;
                    line-height: 100%;
                   }
               }
            .diff{
                color: #FFABBC;
            }
    
        }
        .subhead{
            width: 593px;
            height: 60px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #190714;
            margin: 20px auto;
            @media(max-width:913px){
                width: 60%;
                margin: 20px auto;
                @media(max-width:500px){
                    width: 266px;
                    font-size: 12px;
                    line-height: 15px;
                   }
               }
        }
        form{
            position: relative;
            width: 720px;
            margin:30px auto 150px auto;
            @media(max-width:825px){
                width: unset;
                display:flex;
                flex-direction: column;
                @media(max-width:667px){
                    margin-top: 60px;
                   }
               }
            &>input{
                width: 720px;
                background: #FFFFFF;
                box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
                border: none;
                border-radius: 29.5px;
                outline: none;
                padding: 20px 40px;
                height: 59px;
                font-family: 'Aristotelica Display Trial';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 22px;
                &::placeholder{
                    color: rgba(196, 196, 196, 0.5);
                }
                
                @media(max-width:825px){
                    width: 80%;
                    margin: 20px auto;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
                    border-radius: 5px;
    
                    @media(max-width:667px){
                         border-radius: 29.5px;                
                         @media(max-width:500px){
                            width: 324px;
                             border-radius: 29.5px;                
    
                           }
                       }
                   }
            }
            button{
                outline: none;
                width: 213px;
                border: none;
                background: #FFABBC;
                padding: 20px;
                position: absolute;
                border-radius: 29.5px;                
                height: 59px;
                right: 1px;
                @media(max-width:825px){
                    margin: 20px auto;
                    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    
                    @media(max-width:667px){
                        width: 120px;
                        img{
                            margin: 0 !important;
                            transform: scale(1.6);
                        }
                        @media(max-width:443px){
                            width: 80px;
                           
                           }
                       }
                   }
                span{
                    font-family: 'Aristotelica Display Trial';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 26px;
                    color: #FFFFFF;
                    @media(max-width:667px){
                        display: none;
                       }
                }
            }
        }
    }
}