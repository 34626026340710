.container{
    width: 600px;
    margin: auto;
    position: relative;
    z-index: 10;
    .modal{
        border-radius: 10px;
        position: relative;
        background: #FFFFFF;
        overflow: hidden ;
        .logo{
            position: absolute;
            left:1%;
            top: 5%;
            transform: scale(0.6);
        }
        .dismiss{
            position: absolute;
            right:5%;
            top: 5%;
            font-size: 60px;
            cursor: pointer;
            &:hover{
                color: red;
            }
            @media (max-width:460px) {
              font-size: 40px;
            }
        }
        .text{
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height:35px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding:100px;
            color: #190714;
            @media (max-width:540px) {
                font-size: 26px;
                line-height: 29px;
                padding: 80px;
            }
        }
        .btnCont{
            width: 80%;
            margin: auto;
            margin-top: -80px;
            display: flex;
            justify-content: center;
            .btn{
                transform: scale(0.7);
                border-radius: 5px;
                margin-right: -20px;
                @media (max-width:486px) {
                    margin-right: 0;
                    @media (max-width:440px) {
                        &.img2{
                            margin-right: 4px;
                        }
                        &.img1{
                            margin-right: -57px;
                        }
                        transform: scale(0.6);
                    }
                }
            }
            @media (max-width:440px) {
                // width: 106px;
            }
        }
    }
    @media (max-width:640px) {
        width: 95% ;
        display: flex;
        justify-content: center;
        .modal{
            width:95%;
           
        }
    }
}

