:root {
  --gold: #ffd873;
  --green: #10c06d;
  --yellow-deep: #f9a825;
  --text-primary: #002058;
  --text-dark: #041942;
  --text-input: #b2b7bc;
  --fade: #f5f5f5;
  --grey: #b3b3b3;
  --dark-blue: #152144;
  --alert: #f8d7da;
  --alert-color: #721c24;
  --alert-border: #f5c6cb;
  --white: #fff;
  --full: 100%;
  --radius: 2px;
  --wrapper-max-width: 1680px;
  --wrapper-min-width: 240px;
}
