.blogdescription{
    .diff{
        color: #FFABBC;
    }
    .divtext{
        background-color: #fff;
        padding: 100px;
        @media (max-width:1127px) {
            padding: 100px 50px;
            @media (max-width:600px) {
                padding: 10px 20px;
            }
        }
        img{
            width: 100%;
        }
        .title{
            width: 643px;
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            font-weight: 600;
            font-size: 75px;
            line-height: 93.5%;
            text-align: center;
            color: #190714;
            margin: 40px auto;
            @media (max-width:796px) {
                width: 100%;
                font-size: 60px;
                @media (max-width:600px) {
                    width: 246px;
                    font-size: 32px;
                    line-height: 93.5%;
                }
            }
           
        }
        .subtitle{
            width: 613px;
            margin:0 auto 80px  auto;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #ABA5A6;
            @media (max-width:796px) {
                width: 100%;
                @media (max-width:600px) {
                    width: 290px;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }
    .img{
        width: 90%;
        // height: 443px;
        margin: 30px auto;
        cursor: not-allowed;
        display: flex;
        justify-content: center;
    }
    .text{
        width: 1240px;
        margin: 20px auto;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        color: #ABA5A6;

        @media (max-width:1554px) {
            width:90%;

        }

        .p20{
            margin: 20px 0;
        }
    }
    .industry{
        width: 1240px;
        margin: 20px auto;

        @media (max-width:1367px) {
            width: 90%;
        }

        .title{
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
            line-height: 93.5%;
            color: #190714;
            margin-top: 40px;
            @media (max-width:550px) {
               font-size: 22px;
               width: 219px;
            }
        }
        .img{
            width: 1240px;
            // height: 717px;
            margin: 10px 0;
            @media (max-width:1260px) {
                width: 90%;
                margin: 0 auto 10px 0;
            }
        }
    }
    .more{
        width: 1240px;
        margin: 30px auto;
        font-family: 'Aristotelica Display Trial';
        font-style: normal;
        font-weight: 600;
        font-size: 64px;
        line-height: 96%;
        color: #190714;

        .bbn{
            display: none;
         @media (max-width:580px) {
            width: 189px;
            height: 41px;
            margin: 20px auto;
            background: #FFABBC;
            border-radius: 5px;
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-weight: 600;
            border: none;
            font-size: 13px;
            line-height: 14px;
            color: #FFFFFF;
         }

        }
       @media (max-width:1260px) {
           width: 90%;
           @media (max-width:1098px) {
               .section{
                   width: 90% !important;
               }
            .title{
                font-size: 40px;
                margin-left: 20px ;
                @media (max-width:1098px) {
                        font-size: 22px;
                        line-height: 93.5%;
                        margin: 20px 0;
                }
            }
        }
       }
        .section{
            width: 1029px;
            display: flex;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            padding: 10px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);

            @media (max-width:1555px) {
                img{
                    margin-left: 20px;
                }
                @media (max-width:600px) {
                    flex-direction: column;
                    img{
                        width: 200px !important;
                    }
                }

            }
        .title{
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 93.5%;
            color: #FFABBC;

           
        }
        .subtitle{
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 40px;
            width: 380px;
            margin: 10px 0;
            color: #190714;

            @media (max-width:600px) {
                width: 355px;
                font-size: 20px;
                line-height: 22px;
            }

        }
        .time{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #1D1E2C;

            .clock{
                width: 156px;
                height: 15.6px;
            }
        }
        img{
            height: 180px;
            width: 260px ;
        }
       
        .div{
            padding: 20px 40px;
            @media (max-width:600px) {
                padding: 20px;
            }
        }
        }

    }
    @media (max-width:1554px) {
        img{
            // width: 80% !important;
        }
    }
    .blog-details{
        padding:50px;
        font-family: Inter;
font-size: 22px;
font-weight: 400;
color:#000;
h2{
    margin-bottom: 10px;
}
p{
    line-height: 35px;
}

    }
}