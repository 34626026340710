.care{
    width: 1240px;
    margin:100px auto;
    display: flex;
    padding: 20px;
    background-color: #190714;
    box-shadow: 0px 10px 70px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    img{
        transform: scale(0.99);
        @media(max-width:1077px){
            transform: scale(0.88);
            @media (max-width:460px) {
                transform: scale(1);
            }
        }

    }

    .flex{
        width: 531px;
        color:#fff;
        margin: 20px 200px;

        .title{
            font-family: 'Aristotelica Display Trial';
            font-style: normal;
            font-weight: 600;
            font-size: 75px;
            line-height: 95%;
            margin: 60px 0 10px 0;
            .diff{
                color:  #FFABBC;
            }
            @media (max-width:460px) {
                font-size: 36px;
                line-height: 95%;
                width: 246px;
                margin-top: -20px;
            }
        }
        .subtitle{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            margin: 10px 0;
            @media (max-width:460px) {
                font-size: 12px;
                line-height: 15px;
                width: 305px;
            }
        }
        @media(max-width:1335px){
            margin: 20px 100px;
        }
        @media(max-width:1203px){
            margin: 20px 20px;
        }transform: scale(0.9);
        .btnCon{
            display: flex;
            max-width: "460px";
            .btn{
                display: flex;
                align-items: center;
                margin:0 10px 0 0;
                background: #FFABBC;
                border-radius: 5px;
                font-weight: 600;
                font-size: 24px;
                line-height: 26px;
                color: #fff;
                border: none;
                outline: none;
                max-width: 220px;
                padding: 10px 32px 10px 33px;
                width: 220px;
                span{
                    margin-left:10px;
                }
                @media (max-width:422px) {
                    font-size: 18px;
                    width: 184px;
                    @media (max-width:410px) {
                       width: 162px;
                       @media (max-width:338px) {
                       width: 136px;
                       font-size: 14px;
                       line-height: 16px;
                      }
                      }
                  }
            }
        }
        
        @media(max-width:1077px){
            margin: 20px -20px;
            width: unset !important;
        }
        @media (max-width:460px) {
            padding: 20px;
        }
    }
   @media (max-width:1280px) {
       width: 90%;
       margin: auto;
    @media(max-width:1203px){
        padding: 10px;
        .img{
            height:600px ;
            @media (max-width:1075px) {
                margin: 20px 10px 0 -20px;
            }
          
        }
        .flex{
            width: 90%;
        }
        @media (max-width:998px) {
            flex-direction: column;
            padding:0 20px;
            @media (max-width:900px) {
            
                .img{
                    transform: scale(1);
                    height: auto;
                    margin: 20px ;
                    border-radius: 42px;

                }
                @media (max-width:460px) {
                    padding: 0;
                    .img{
                        transform: scale(1);
                    }
                }
        }
        
            
        }
        
    }
   
   }
}