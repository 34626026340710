.product-single{
    width: 312px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding:10px;
    img{
        margin-bottom: 10px;
    }
    p{
        padding: 0px 10px;
    }
    .category{
        font-family: 'Aristotelica Display Trial';
        font-size: 14px;
        font-weight: 600;
        color: #979797;
        span{
            font-family: 'Inter';
            float: right;
            color:#1D1E2C;
            font-weight: 700;
        }
    }
    .name{
        font-family: 'Aristotelica Display Trial';
        font-size: 20px;
        font-weight: 600;
        color: #1D1E2C
    }
    .description{
        font-family: 'Inter';
        font-size: 14px;
        font-weight: 400;
        color: #6C6C6C
    }
    .link{
background-color: #FFABBC;
color: white;
height: 32px;
font-family: 'Aristotelica Display Trial';
display: flex;
justify-content: center;
align-items: center;
margin: 10px 10px 10px 10px;
    }
}